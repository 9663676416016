import {appSettings} from "./config";

// For a full list of MSAL.js configuration parameters, 
// visit https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md

//per cnh cert

export const msalConfig = {
    auth: {
        clientId: "01f7d069-cd2f-45ec-9fd3-1bf53fcf082f",
        authority: "https://login.microsoftonline.com/79310fb0-d39b-486b-b77b-25f3e0c82a0e",
        redirectUri: appSettings.authRedirectUri,
        validateAuthority: true,
        navigateToLoginRequestUrl: false
    },
    cache: {
        cacheLocation: "localStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
    }
}

//per cnh prod
/*
export const msalConfig = {
   auth: {
       clientId: "ff1977fd-2904-4085-b781-8c827fe91e03",
       authority: "https://login.microsoftonline.com/79310fb0-d39b-486b-b77b-25f3e0c82a0e",
       redirectUri: appSettings.authRedirectUri,
       validateAuthority: true,
       navigateToLoginRequestUrl: false
   },
   cache: {
       cacheLocation: "localStorage", // This configures where your cache will be stored
       storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
   }
}
*/
//per dev in rada
/*
export const msalConfig = {
    auth: {
        clientId: "6af0873c-7d77-4372-ba94-1eba24aa7462",
        authority: "https://login.microsoftonline.com/af930677-c280-45c3-aaf1-bb933f5c5cbc",
        redirectUri: appSettings.authRedirectUri,
        validateAuthority: true,
        navigateToLoginRequestUrl: false
    },
    cache: {
        cacheLocation: "localStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
    }
}
*/
// Coordinates and required scopes for your web API
export const apiConfig = {
//    //resourceUri: "https://tecotechapi-dev.azurewebsites.net/api/profile",
//    //resourceUri: "https://tecotechapi-dev.azurewebsites.net/api/profile",
//    //per dev in rada
//     resourceScopes: "api://aafe348a-e351-49c3-bf8c-3dd3ca454a78/.default"
//    //per cnh cert
      resourceScopes: "api://2bf81e35-61c5-4920-a6b1-8b1fd5be7695/.default"
//    //per cnh prod
//      resourceScopes: "api://a286443d-20fc-4ad6-a2dd-19fab1473a3c/.default"
}

/** 
 * Scopes you enter here will be consented once you authenticate. For a full list of available authentication parameters, 
 * visit https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
//per cnh cert

export const loginRequest = {
    scopes: ["openid", "profile", "offline_access", "api://2bf81e35-61c5-4920-a6b1-8b1fd5be7695/.default"]
    }
    // Add here scopes for access token to be used at the API endpoints.
    export const tokenRequest = {
    scopes: ["api://2bf81e35-61c5-4920-a6b1-8b1fd5be7695/.default"]
    }
    // Add here scopes for silent token request
    export const silentRequest = {
    scopes: ["openid", "profile", "api://2bf81e35-61c5-4920-a6b1-8b1fd5be7695/.default"]
}


//per cnh prod
/*
export const loginRequest = {
   scopes: ["openid", "profile", "offline_access", "api://a286443d-20fc-4ad6-a2dd-19fab1473a3c/.default"]
}

// Add here scopes for access token to be used at the API endpoints.
export const tokenRequest = {
   scopes: ["api://a286443d-20fc-4ad6-a2dd-19fab1473a3c/.default"]
}

// Add here scopes for silent token request
export const silentRequest = {
  scopes: ["openid", "profile", "api://a286443d-20fc-4ad6-a2dd-19fab1473a3c/.default"]
}
*/

//per dev in rada
/*
export const loginRequest = {
    scopes: ["openid", "profile", "offline_access", "api://aafe348a-e351-49c3-bf8c-3dd3ca454a78/.default"]
}

// Add here scopes for access token to be used at the API endpoints.
export const tokenRequest = {
    scopes: ["api://aafe348a-e351-49c3-bf8c-3dd3ca454a78/.default"]
}

// Add here scopes for silent token request
export const silentRequest = {
    scopes: ["openid", "profile", "api://aafe348a-e351-49c3-bf8c-3dd3ca454a78/.default"]
}
*/