export const auth0Config = {
  client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN
};

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
};

export const appSettings = {
  
  //*****PROD CNH*******
  //webApi: "https://eutetechappsvcp02.azurewebsites.net/api/v1/",
  //authRedirectUri: "https://eutetechappsvcp01.azurewebsites.net/login"

  //*****CERT CNH*******
  webApi: "https://eutetechappsvcc02.azurewebsites.net/api/v1/",
  authRedirectUri: "https://eutetechappsvcc01.azurewebsites.net/login"
  
  // //*****DEV*******
  //  webApi:"http://localhost:44351/api/v1/",
  //  authRedirectUri: "http://localhost:3000/login"

  //*****RADA*******
  //webApi: "https://tecotechapi-dev.azurewebsites.net/api/v1/",
  //authRedirectUri: "https://terotechweb.azurewebsites.net/login"
}

//cert CNH Audit

export const auditTool = {
webUrl: "https://eutetechappsvcc03.azurewebsites.net/",
key: 'audit-tool'
}


//prod CNH Audit
/*
export const auditTool = {
 webUrl: "https://eutetechappsvcp03.azurewebsites.net/",
 key: 'audit-tool'
}
*/
//dev

// export const auditTool = {
//   webUrl: "http://localhost:3001/",
//   key: 'audit-tool'
// }


